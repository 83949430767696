import {
  computed,
  ref, watch,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { isEnableBed, parseDateToString } from '@/auth/utils';
import moment from 'moment';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import contractStoreModule from '../contractStoreModule';

export default function useMoveContract(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'contract';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, contractStoreModule);
  }
  const { t } = useI18nUtils();
  const toastification = toast();
  const refModalMoveContract = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(null);
  const resetItemLocal = () => {
    itemLocal.value = null;
  };
  const isSubmitting = ref(false);
  const isLoading = ref(false);
  const remainDiscount = ref(0);
  const submitData = ref(itemLocal.value && itemLocal.value.apartment ? {
    apartment: itemLocal.value.apartment,
    room: null,
    bed: null,
    price: 0,
    deposit: 0,
    promotionMonth: itemLocal.value.promotionMonth,
    promotionPricePerMonth: itemLocal.value.promotionPricePerMonth,
    depositMonthObject: itemLocal.value.depositMonthObject,
    note: itemLocal.value.note,
    moveDate: moment(new Date()).toDate(),
    contractFees: itemLocal.value.contractFees ? itemLocal.value.contractFees.map(eachFee => ({ ...eachFee, meter: null, billingDate: moment(new Date()).toDate() })) : [],
    liquidHandover: [],
    handover: [],
  } : {});
  const itemId = computed(() => (props.item && props.item.id ? props.item.id : null));
  const fetchDetailData = () => {
    if (itemId.value) {
      isLoading.value = true;
      store.dispatch('contract/getContractDetail', itemId.value)
        .then(response => {
          itemLocal.value = response.data;
          submitData.value = itemLocal.value && itemLocal.value.apartment ? {
            apartment: itemLocal.value.apartment,
            room: null,
            bed: null,
            price: 0,
            deposit: 0,
            promotionMonth: itemLocal.value.promotionMonth,
            promotionPricePerMonth: itemLocal.value.promotionPricePerMonth,
            depositMonthObject: itemLocal.value.depositMonthObject,
            note: itemLocal.value.note,
            moveDate: moment(new Date()).toDate(),
            contractFees: itemLocal.value.contractFees ? itemLocal.value.contractFees.map(eachFee => ({ ...eachFee, meter: null, billingDate: moment(new Date()).toDate() })) : [],
            liquidHandover: [],
            handover: [],
          } : {};
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = true;
        });
    }
  };
  const onOpen = () => {
    resetItemLocal();
    fetchDetailData();
  };

  const room = computed(() => submitData.value.room);
  const bed = computed(() => submitData.value.bed);
  const moveDate = computed(() => submitData.value.moveDate);
  const isTingTong = process.env.VUE_APP_PROVIDER === 'tingtong';
  const calculateRemainDiscount = () => {
    if (props.item.id && props.item.id > 0) {
      store.dispatch('contract/getRemainDiscount', props.item.id).then(response => {
        remainDiscount.value = response.data;
        if (remainDiscount.value > 0 && itemLocal.value.promotionPricePerMonth > 0) {
          if (remainDiscount.value % itemLocal.value.promotionPricePerMonth === 0) {
            submitData.value.promotionMonth = remainDiscount.value / itemLocal.value.promotionPricePerMonth;
          } else {
            submitData.value.promotionMonth = 0;
            submitData.value.promotionPricePerMonth = 0;
          }
        }
      });
    }
  };
  const resetModal = () => {
    resetItemLocal();
    submitData.value = itemLocal.value && itemLocal.value.apartment ? {
      apartment: itemLocal.value.apartment,
      room: null,
      bed: null,
      price: 0,
      deposit: 0,
      promotionMonth: itemLocal.value.promotionMonth,
      promotionPricePerMonth: itemLocal.value.promotionPricePerMonth,
      depositMonthObject: itemLocal.value.depositMonthObject,
      note: '',
      moveDate: moment(new Date()).toDate(),
      contractFees: (itemLocal.value.contractFees ?? []).map(eachFee => ({ ...eachFee, meter: null })),
      liquidHandover: [],
      handover: [],
    } : {};
    calculateRemainDiscount();
    isSubmitting.value = false;
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      contractId: props.item.id,
      apartmentId: submitData.value.apartment.id,
      roomId: submitData.value.room.id,
      bedId: submitData.value.bed ? submitData.value.bed.id : null,
      moveDate: parseDateToString(submitData.value.moveDate),
      price: submitData.value.price,
      deposit: submitData.value.depositMonthObject ? submitData.value.depositMonthObject.id * submitData.value.price : submitData.value.deposit,
      moveFee: submitData.value.moveFee,
      promotionMonth: submitData.value.promotionMonth,
      promotionPricePerMonth: submitData.value.promotionPricePerMonth,
    };

    // list service
    data.fees = submitData.value.contractFees ? submitData.value.contractFees.map(contractFee => {
      const feeItem = {
        id: contractFee.fee.id,
        coefficient: contractFee.coefficient,
        quantity: contractFee.quantity,
        firstIndex: contractFee.firstIndex,
      };
      feeItem.billingDate = parseDateToString(contractFee.billingDate);

      if (contractFee.meter && contractFee.meter.id > 0) {
        feeItem.meterId = contractFee.meter.id;
      }
      return feeItem;
    }) : [];

    // handover
    if (submitData.value.handover && submitData.value.handover.length > 0) {
      data.handover = submitData.value.handover.map(object => {
        const temp = {
          assetId: object.asset.id,
          isNormal: object.isNormal,
          note: object.note,
          base64String: object.base64String,
        };
        if (object.id && object.id > 0) {
          temp.id = object.id;
        }
        return temp;
      });
    }

    if (submitData.value.liquidHandover && submitData.value.liquidHandover.length > 0) {
      data.liquidHandover = submitData.value.liquidHandover.map(object => ({
        assetId: object.asset.id,
        note: object.note,
        repairFee: object.repairFee,
        isNormal: object.isNormal,
        base64String: object.base64String,
      }));
    }

    if (submitData.value.otherHandover && submitData.value.otherHandover.length > 0) {
      data.otherHandover = submitData.value.otherHandover.map(object => {
        const temp = {
          name: object.name,
          status: object.status,
          note: object.note,
          penaltyFee: object.penaltyFee,
        };
        if (object.id && object.id > 0) {
          temp.id = object.id;
        }
        return temp;
      });
    }

    if (submitData.value.liquidOtherHandover && submitData.value.liquidOtherHandover.length > 0) {
      data.liquidOtherHandover = submitData.value.liquidOtherHandover.map(object => ({
        oldDataId: object.oldData.id,
        note: object.note,
        name: object.name,
        status: object.status,
        penaltyFee: object.penaltyFee,
      }));
    }

    const dispatchName = "contract/moveContract";

    isSubmitting.value = true;
    store
      .dispatch(dispatchName, data)
      .then(response => { emit('on-item-updated', response.data); })
      .then(() => {
        refModalMoveContract.value.toggle('#toggle-btn');
      })
      .then(() => {
        emit('refetch-data');
      })
      .then(() => {
        toastification.showToastUpdateSuccess();
        isSubmitting.value = false;
      })
      .catch(error => {
        toastification.showToastError(error, refForm.value);
        isSubmitting.value = false;
      });
  };

  const resolveColWidthIfDisableBed = isBed => {
    if (isEnableBed()) {
      return 4;
    }
    if (isBed) {
      return 0;
    }
    return 6;
  };

  const onAddService = listServiceSelected => {
    const listService = listServiceSelected.map(obj => ({
      fee: obj,
      quantity: 1,
      coefficient: 1,
      firstIndex: 0,
      billingDate: submitData.value.moveDate,
      lastBillingDate: submitData.value.moveDate,
      meter: null,
    }));
    const arrMixUpServices = submitData.value.contractFees ? [...submitData.value.contractFees, ...listService] : [...listService];
    submitData.value.contractFees = arrMixUpServices;
  };

  watch(room, val => {
    // autoFillPriceAndDeposit();
    if (val) {
      submitData.value.price = val.price;
      submitData.value.deposit = val.deposit;
      if (isTingTong) {
        if (val.price >= itemLocal.value.price) {
          submitData.value.moveFee = 300000;
        } else {
          submitData.value.moveFee = 500000;
        }
      }
    }
  });

  watch(bed, val => {
    // autoFillPriceAndDeposit();
    if (val) {
      submitData.value.price = val.price;
      submitData.value.deposit = val.deposit;
    }
  });

  watch(moveDate, val => {
    // autoFillPriceAndDeposit();
    if (val && submitData.value.contractFees && submitData.value.contractFees.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < submitData.value.contractFees.length; index++) {
        const contractFee = submitData.value.contractFees[index];
        contractFee.billingDate = val;
        submitData.value.contractFees.splice(index, 1, contractFee);
      }
    }
  });

  return {
    refModalMoveContract,
    refForm,
    itemLocal,
    submitData,
    isSubmitting,
    isTingTong,
    remainDiscount,
    resetItemLocal,
    resetModal,
    onSubmit,
    resolveColWidthIfDisableBed,
    onAddService,
    t,
    onOpen,
    isLoading,
  };
}
